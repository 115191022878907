import { graphql, useStaticQuery } from 'gatsby';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import {
  CsDriverType,
  CsQuestion,
  CsQuestionWithPlaceholder,
  CsRepeatableSection,
} from 'types/contentStack';
import {
  DriverType,
  NestedQuestions,
  Question,
  QuestionWithOptionalPlaceholder,
} from 'types/forms';

export type LicenceRestrictionsQuestions = {
  addLicenceRestrictions: Question;
  licenceRestrictions: NestedQuestions<{
    restrictionCode: QuestionWithOptionalPlaceholder;
  }>;
};

type CsLicenceRestrictionsQuestions = {
  allCsAddDriverLicenceRestrictionsTemplate: {
    nodes: {
      driver_type: CsDriverType;
      add_licence_restrictions: CsQuestion;
      licence_restrictions: CsRepeatableSection;
    }[];
  };
  allCsAddDriverLicenceRestrictionsQuestionsTemplate: {
    nodes: { driver_type: CsDriverType; restriction_code: CsQuestionWithPlaceholder }[];
  };
};

const query = graphql`
  query {
    allCsAddDriverLicenceRestrictionsTemplate {
      nodes {
        driver_type
        add_licence_restrictions {
          main_details {
            question_text
            explanatory_text
            tooltip {
              body
              button_text {
                open
                close
              }
            }
            alert_text
          }
        }
        licence_restrictions {
          label
          add_text
          remove_text
          confirm_delete_text
        }
      }
    }
    allCsAddDriverLicenceRestrictionsQuestionsTemplate {
      nodes {
        driver_type
        restriction_code {
          main_details {
            question_text
            explanatory_text
            tooltip {
              body
              button_text {
                open
                close
              }
            }
            alert_text
            placeholder
          }
        }
      }
    }
  }
`;

const useLicenceRestrictionsQuestions = (driverType: DriverType): LicenceRestrictionsQuestions => {
  const {
    allCsAddDriverLicenceRestrictionsTemplate,
    allCsAddDriverLicenceRestrictionsQuestionsTemplate,
  } = useStaticQuery<CsLicenceRestrictionsQuestions>(query);
  const { processQuestion, processQuestionWithOptionalPlaceholder } = useQuestionProcessor();
  const licenceRestrictionsQuestions = allCsAddDriverLicenceRestrictionsTemplate.nodes.find(
    (questions) => questions.driver_type === driverType
  );
  const nestedLicenceRestrictionsQuestions = allCsAddDriverLicenceRestrictionsQuestionsTemplate.nodes.find(
    (questions) => questions.driver_type === driverType
  );

  /* istanbul ignore if */
  if (!licenceRestrictionsQuestions || !nestedLicenceRestrictionsQuestions) {
    throw new Error(`Licence restrictions missing for ${driverType}`);
  }

  return {
    addLicenceRestrictions: processQuestion(licenceRestrictionsQuestions.add_licence_restrictions),
    licenceRestrictions: {
      label: licenceRestrictionsQuestions.licence_restrictions.label,
      addText: licenceRestrictionsQuestions.licence_restrictions.add_text,
      removeText: licenceRestrictionsQuestions.licence_restrictions.remove_text,
      confirmDeleteText: licenceRestrictionsQuestions.licence_restrictions.confirm_delete_text,
      restrictionCode: processQuestionWithOptionalPlaceholder(
        nestedLicenceRestrictionsQuestions.restriction_code
      ),
    },
  };
};

export default useLicenceRestrictionsQuestions;
