import TypeaheadInput, {
  Option as TypeaheadOption,
} from '@rsa-digital/evo-shared-components/components/Form/TypeaheadInput';
import { FieldFunction, StringField } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import React from 'react';
import {
  LicenceRestriction,
  LicenceRestrictionWithKey,
} from 'state/forms/licenceRestrictions/state';
import useReferenceData from 'state/referenceData/useReferenceData';
import QuestionField from 'components/QuestionField';
import { trackTypeaheadFocusWithDebounce } from 'helpers/eventTracking';
import { DriverType } from 'types/forms';
import useLicenceRestrictionsQuestions from '../questions';

type LicenceRestrictionFormProps = {
  idPrefix: string;
  restriction: LicenceRestriction;
  updateLicenceRestriction: (update: Partial<LicenceRestrictionWithKey>) => void;
  getError: FieldFunction<LicenceRestriction, string | undefined>;
  showValidation: FieldFunction<LicenceRestriction, void>;
  driverType: DriverType;
};

const LicenceRestrictionForm: React.FC<LicenceRestrictionFormProps> = ({
  restriction,
  updateLicenceRestriction,
  idPrefix,
  getError,
  showValidation,
  driverType,
}) => {
  const questions = useLicenceRestrictionsQuestions(driverType).licenceRestrictions;

  const getIdForInput = (input: string): string => `${idPrefix}${input}`;

  const licenceRestrictionsRefData = useReferenceData('licenceRestrictions');

  const restrictionsRefData =
    licenceRestrictionsRefData?.restrictionCodes.map((code) => ({
      id: code.value,
      label: code.name,
    })) ?? [];

  const getTypeaheadValue = (
    id: StringField<LicenceRestriction>,
    options: TypeaheadOption[] | undefined
  ): TypeaheadOption | undefined => {
    return options?.find((option) => option.id === restriction[id]);
  };

  return (
    <>
      <QuestionField question={questions.restrictionCode} errorText={getError('restrictionCode')}>
        <TypeaheadInput
          id={getIdForInput('restrictionCode')}
          value={getTypeaheadValue('restrictionCode', restrictionsRefData)}
          options={restrictionsRefData}
          onChange={(value) =>
            updateLicenceRestriction({
              restrictionCode: value?.id,
            })
          }
          onBlur={() => showValidation('restrictionCode')}
          onFocus={trackTypeaheadFocusWithDebounce(getIdForInput('restrictionCode'))}
          placeholder={questions.restrictionCode.placeholder}
          minLength={2}
        />
      </QuestionField>
    </>
  );
};

export default LicenceRestrictionForm;
