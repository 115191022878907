import RepeatableSection from '@rsa-digital/evo-shared-components/components/Form/RepeatableSection';
import { buildPartialNestedFieldId } from '@rsa-digital/evo-shared-components/helpers/forms/buildNestedFieldId';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import driversService from 'apiHelpers/drivers/service';
import { navigate } from 'gatsby';
import React from 'react';
import { useAboutTheDriver } from 'state/forms/aboutTheDriver/action';
import { useClaimsHistory } from 'state/forms/claimsHistory/action';
import { useDriverDetails } from 'state/forms/driverDetails/action';
import { useDrivingOffences } from 'state/forms/drivingOffences/action';
import { useKnockoutOverrides } from 'state/forms/knockoutOverrides/action';
import { useLicenceDetails } from 'state/forms/licenceDetails/action';
import { useLicenceRestrictions } from 'state/forms/licenceRestrictions/action';
import {
  initialLicenceRestriction,
  LicenceRestrictionsState,
  LicenceRestrictionWithKey,
} from 'state/forms/licenceRestrictions/state';
import { useNewDriversCover } from 'state/forms/newDriversCover/action';
import { useReplaceDriver } from 'state/forms/replaceDriver/action';
import BooleanRadioInput from 'components/BooleanRadioInput';
import KnockoutOverrideModal from 'components/Modals/KnockoutOverride';
import QuestionField from 'components/QuestionField';
import { isTemporaryDriver } from 'helpers/driverTypeHelper';
import {
  trackButtonClick,
  trackFieldError,
  trackModalOpen,
  trackNamedDriverFormContinue,
} from 'helpers/eventTracking';
import { formNameStepMapping } from 'helpers/formNameStepMapping';
import { scrollAndTrackError } from 'helpers/forms';
import { addItem, removeItem, updateItem } from 'helpers/repeatableForm';
import { FormPage, getFormPageRoute } from 'helpers/routingHelper';
import useKnockoutErrorHandler from 'helpers/useKnockoutErrorHandler';
import usePolicyNumber from 'helpers/usePolicyNumber';
import useRequestHandler from 'helpers/useRequestHandler';
import useResetKnockoutOverride from 'helpers/useResetKnockoutOverride';
import { DriverType } from 'types/forms';
import LicenceRestrictionForm from './LicenceRestriction';
import useLicenceRestrictionsQuestions from './questions';
import useLicenceRestrictionsRules from './validation';
import FormFooter from '../FormFooter';

const LicenceRestrictionsForm: React.FC<{ driverType: DriverType }> = ({ driverType }) => {
  const [licenceRestrictions, updateLicenceRestrictions] = useLicenceRestrictions();
  const [driverDetails] = useDriverDetails();
  const [aboutTheDriver] = useAboutTheDriver();
  const [drivingOffences] = useDrivingOffences();
  const [licenceDetails] = useLicenceDetails();
  const [claimsHistory] = useClaimsHistory();
  const [newDriversCover] = useNewDriversCover();
  const handleRequest = useRequestHandler();
  const questions = useLicenceRestrictionsQuestions(driverType);
  const rules = useLicenceRestrictionsRules(driverType);
  const [replaceDriverState] = useReplaceDriver();
  const [knockoutOverrides] = useKnockoutOverrides();
  const [potentialOverrideErrorCode, knockoutErrorHandler] = useKnockoutErrorHandler();
  const policyNumber = usePolicyNumber();
  const { getError, validateOnSubmit, showValidation } = useValidation(
    licenceRestrictions,
    rules,
    trackFieldError
  );

  useResetKnockoutOverride(FormPage.LicenceRestrictions);

  const successNavigationUrl = (): string => {
    if (isTemporaryDriver(driverType)) {
      return getFormPageRoute(driverType, FormPage.HirerAgreement);
    }
    return getFormPageRoute(driverType, FormPage.PermanentDriverAgreement);
  };

  const validateRequest = async (): Promise<void> =>
    driversService
      .validateAddDriverRequestLicenceRestrictions(
        policyNumber,
        aboutTheDriver,
        driverDetails,
        claimsHistory,
        drivingOffences,
        licenceDetails,
        licenceRestrictions,
        newDriversCover,
        knockoutOverrides,
        isTemporaryDriver(driverType)
      )
      .then(() => navigate(successNavigationUrl()));

  const handleSuccess = async (): Promise<void> => {
    trackNamedDriverFormContinue(
      {
        formPage: 'Licence restrictions',
        formStep: formNameStepMapping.licenceRestrictions,
        anyRestrictions: licenceRestrictions.addLicenceRestrictions,
        licenceRestrictions: licenceRestrictions.addLicenceRestrictions
          ? licenceRestrictions.licenceRestrictions.flatMap(
              (restriction) => restriction.restrictionCode
            )
          : [],
      },
      replaceDriverState.replacedDriverRef
    );
    await handleRequest(validateRequest, true, knockoutErrorHandler);
  };

  const getLicenceRestrictionIdPrefix = (index: number): string =>
    buildPartialNestedFieldId('licenceRestrictions', index);

  const knockoutOverrideModal = potentialOverrideErrorCode && (
    <KnockoutOverrideModal
      validationRequest={handleSuccess}
      overridableErrorCode={potentialOverrideErrorCode}
    />
  );

  return (
    <form onSubmit={validateOnSubmit(handleSuccess, scrollAndTrackError)}>
      <QuestionField
        question={questions.addLicenceRestrictions}
        errorText={getError('addLicenceRestrictions')}>
        <BooleanRadioInput
          id="addLicenceRestrictions"
          value={licenceRestrictions.addLicenceRestrictions}
          onChange={(newValue) => {
            const update: Partial<LicenceRestrictionsState> = {
              addLicenceRestrictions: newValue,
            };
            if (newValue && licenceRestrictions.licenceRestrictions.length === 0) {
              update.licenceRestrictions = addItem<LicenceRestrictionWithKey>(
                [],
                initialLicenceRestriction
              );
            }
            updateLicenceRestrictions(update);
          }}
        />
      </QuestionField>
      {licenceRestrictions.addLicenceRestrictions && (
        <RepeatableSection
          onAdd={() => {
            trackButtonClick('addButton', 'licenceRestrictions')();
            updateLicenceRestrictions({
              licenceRestrictions: addItem(
                licenceRestrictions.licenceRestrictions,
                initialLicenceRestriction
              ),
            });
          }}
          onRemove={(index) => {
            const currentRestrictions = licenceRestrictions.licenceRestrictions;
            const updatedRestrictions = removeItem(currentRestrictions, index);
            const update: Partial<LicenceRestrictionsState> = {
              licenceRestrictions: updatedRestrictions,
              addLicenceRestrictions: updatedRestrictions.length >= 1,
            };
            updateLicenceRestrictions(update);
          }}
          disableAdd={licenceRestrictions.licenceRestrictions.length >= 5}
          label={questions.licenceRestrictions.label}
          addText={questions.licenceRestrictions.addText}
          removeText={questions.licenceRestrictions.removeText}
          confirmDeleteMessage={questions.licenceRestrictions.confirmDeleteText}
          allowZeroSections
          alignLeft
          confirmCallbacks={{
            onOpen: trackModalOpen('removeLicenceRestriction'),
            onYesClick: trackButtonClick('removeLicenceRestriction', 'yes'),
            onNoClick: trackButtonClick('removeLicenceRestriction', 'no'),
          }}
          getElementIdForFocus={(index) =>
            `${getLicenceRestrictionIdPrefix(index)}restrictionCode`
          }>
          {licenceRestrictions.licenceRestrictions.map((restriction, index) => (
            <LicenceRestrictionForm
              key={restriction.key}
              idPrefix={getLicenceRestrictionIdPrefix(index)}
              restriction={restriction}
              updateLicenceRestriction={(update) =>
                updateLicenceRestrictions({
                  licenceRestrictions: updateItem(
                    licenceRestrictions.licenceRestrictions,
                    index,
                    update
                  ),
                })
              }
              getError={(id, ...nested) => getError('licenceRestrictions', [id, index], ...nested)}
              showValidation={(id, ...nested) =>
                showValidation('licenceRestrictions', [id, index], ...nested)
              }
              driverType={driverType}
            />
          ))}
        </RepeatableSection>
      )}
      <FormFooter
        backButton={{
          onClick: () => navigate(getFormPageRoute(driverType, FormPage.DrivingOffences)),
        }}
      />
      {knockoutOverrideModal}
    </form>
  );
};

export default LicenceRestrictionsForm;
