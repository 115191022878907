import { required } from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import {
  ValidationRule,
  ValidationRules,
} from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import {
  LicenceRestriction,
  LicenceRestrictionsState,
} from 'state/forms/licenceRestrictions/state';
import { CsDriverType, CsErrorsMissingOnly } from 'types/contentStack';
import { DriverType } from 'types/forms';
import useLicenceRestrictionRules from './LicenceRestriction/validation';

type CsLicenceRestrictionsErrorMessages = {
  allCsAddDriverLicenceRestrictionsTemplate: {
    nodes: { driver_type: CsDriverType; add_licence_restrictions: CsErrorsMissingOnly }[];
  };
};

const query = graphql`
  query {
    allCsAddDriverLicenceRestrictionsTemplate {
      nodes {
        driver_type
        add_licence_restrictions {
          error_messages {
            missing
          }
        }
      }
    }
  }
`;

const processDriverRules = <T>(
  rules?: ValidationRule<T, LicenceRestriction>[]
): ValidationRule<T, LicenceRestrictionsState>[] | undefined =>
  rules?.map(({ validityCondition, ...rest }) => ({
    ...rest,
    validityCondition: (v, data, index) =>
      !data.addLicenceRestrictions || validityCondition(v, data.licenceRestrictions[index]),
  }));

const useLicenceRestrictionsRules = (
  driverType: DriverType
): ValidationRules<LicenceRestrictionsState> => {
  const errorMessages = useStaticQuery<CsLicenceRestrictionsErrorMessages>(query);

  const drivingOffenceRules = useLicenceRestrictionRules(driverType);

  const licenceRestrictionsQuestions = errorMessages.allCsAddDriverLicenceRestrictionsTemplate.nodes.find(
    (questions) => questions.driver_type === driverType
  );

  /* istanbul ignore if */
  if (!licenceRestrictionsQuestions) {
    throw new Error(`Licence restrictions questions missing for ${driverType}`);
  }

  return {
    addLicenceRestrictions: [
      required(licenceRestrictionsQuestions.add_licence_restrictions.error_messages.missing),
    ],
    licenceRestrictions: {
      restrictionCode: processDriverRules(drivingOffenceRules.restrictionCode),
    },
  };
};

export default useLicenceRestrictionsRules;
