import { required } from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { LicenceRestriction } from 'state/forms/licenceRestrictions/state';
import { CsDriverType, CsErrorsMissingOnly } from 'types/contentStack';
import { DriverType } from 'types/forms';

type CsLicenceRestrictionsErrorMessages = {
  allCsAddDriverLicenceRestrictionsQuestionsTemplate: {
    nodes: { driver_type: CsDriverType; restriction_code: CsErrorsMissingOnly }[];
  };
};

const query = graphql`
  query {
    allCsAddDriverLicenceRestrictionsQuestionsTemplate {
      nodes {
        driver_type
        restriction_code {
          error_messages {
            missing
          }
        }
      }
    }
  }
`;

const useLicenceRestrictionRules = (
  driverType: DriverType
): ValidationRules<LicenceRestriction> => {
  const errorMessages = useStaticQuery<CsLicenceRestrictionsErrorMessages>(query);
  const licenceRestrictionsQuestions = errorMessages.allCsAddDriverLicenceRestrictionsQuestionsTemplate.nodes.find(
    (questions) => questions.driver_type === driverType
  );

  /* istanbul ignore if */
  if (!licenceRestrictionsQuestions) {
    throw new Error(`Licence restrictions questions missing for ${driverType}`);
  }

  return {
    restrictionCode: [
      required(licenceRestrictionsQuestions.restriction_code.error_messages.missing),
    ],
  };
};

export default useLicenceRestrictionRules;
