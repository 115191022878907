import { Location } from 'history';
import React from 'react';
import FormHeading from 'components/Forms/FormHeading';
import FormPageWrapper from 'components/Forms/FormPageWrapper';
import LicenceRestrictionsForm from 'components/Forms/LicenceRestrictions';
import Layout from 'components/Layout';
import { useFormRedirect } from 'helpers/useFormRedirect';
import { DriverType } from 'types/forms';

export type LicenceRestrictionsData = {
  meta_title: string;
  heading: string;
};

type LicenceRestrictionsProps = {
  data: LicenceRestrictionsData;
  location: Location;
  driverType: DriverType;
};

const LicenceRestrictionsBaseComponent: React.FC<LicenceRestrictionsProps> = ({
  data,
  location,
  driverType,
}) => {
  useFormRedirect();
  return (
    <FormPageWrapper
      location={location}
      title={data.meta_title}
      formPageName="licenceRestrictions"
      driverType={driverType}>
      <Layout pageType="form" meta={{ title: data.meta_title }}>
        <FormHeading text={data.heading} />
        <LicenceRestrictionsForm driverType={driverType} />
      </Layout>
    </FormPageWrapper>
  );
};

export default LicenceRestrictionsBaseComponent;
